.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "IQOS";
  src: url("./fonts/IQOSW10-Regular.woff") format('woff');
}

html, body, #root, .container {
  font-family: "IQOS" !important;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  min-height: 100%;
  display: flex;
  justify-content: center;
  color: #44546a;
  box-sizing: border-box;
}

#root {
  /* background-image: url(https://www.pmi.com/resources/images/default-source/default-album/blue-gradient.jpg?sfvrsn=2e967db4_0&amp;imwidth=1920%201920w); */
  /* background: linear-gradient(180deg, #007dc4, #00beff) */
  background: #f6f6f6;
}

.container {
  padding: 20px;
  max-width: 600px;
  background: #FEFDFB;
  border-right: 1px solid #d9dbe5;
  border-left: 1px solid #d9dbe5;
}

button, button:focus, button:hover {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #00d1d2;
	outline: inherit;
  padding: 10px 30px;
  line-height: 1.5;
  max-width: 100%;
  color: #34303D;
  font-size: 16px;
  background: #00d1d2;
  border-radius: 2rem;
  font-family: "IQOS" !important;
}

button.ghost {
  background: white;
  border: 1px solid #34303D;
  color: #34303D;
}

.full-width {
  width: 100%;
}

.header {
  display: flex;
  padding-bottom: 20px;
}

.header img {
  height: 84px;
  width: 84px;
  max-width: 84px;
  max-height: 84px;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-center {
  display: flex;
  justify-content: center;
}

h3 {
  font-weight: 400;
}

input[type="text"] {
  width: 100% !important;
  min-height: 0 !important;
  height: 50px;
  font-size: 16px;
  line-height: 11px;
  border-radius: 2rem;
  border: 1px solid #d9dbe5;
  transition: all 0.3s;
  background-color: #fff;
  background-image: none;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 10px 30px;
  box-sizing: border-box;
  margin: 0;
  color: #44546a;
  font-family: "IQOS";
}

input[type="text"].error {
  border: 1px solid #a22f26 !important;
}

input[type="text"]:focus {
  border: 1px solid #00D1D2;
  outline: none;
}

input[type="text"]::placeholder {
  color: #6e6e6e; 
  font-family: "IQOS";
}

button:disabled {
  background-color: #dadada;
  border: 1px solid #dadada;
}

h1 {
  text-align: center;
  margin-top: 0;
}

button.back, button.back:hover, a.hyper {
  color: #00D1D2 !important;
  cursor: pointer;
  border: none;
  background: inherit;
  margin-bottom: 0 !important;
  align-items: baseline;
  padding-left: 0;
}

button.back i {
  margin-right: 5px;
}

.margin-bottom-1em {
  margin-bottom: 1em;
}

img.headerImage {
  height: 216px;
}

.error-message {
  color: #a22f26;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.text-underline {
  text-decoration: underline !important;
}

.text-align-center {
  text-align: center;
}

.font-color-turquoise {
  color: #00D1D2 !important;
}

.cep-image {
  width: 96px;
  height: 96px;
  margin-bottom: 1rem;
}
