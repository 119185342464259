.home-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.home-container img {
  width: 80%;
  max-width: 320px;
  margin-bottom: 2rem;
  margin-bottom: 2rem;
}

.home-container button {
  margin-top: 2rem;
  margin-bottom: 2rem;
  justify-content: space-between;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  font-weight: normal;
  color: inherit;
}
